.dropdown-custom {
  div.ant-col.ant-col-4 {
    div {
      margin-top: 0 !important;
    }
  }
  div.ant-col.ant-col-18 {
    left: -25px !important;
    position: relative;
    [data-id='title'] {
      top: 0 !important;
    }
    [data-id='sub-title'] {
      top: 14px !important;
    }
  }
}
