@import 'antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  src: local('Mulish'), url(./assets/fonts/Mulish-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  src: local('Mulish'), url(./assets/fonts/Mulish-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  src: local('Mulish'), url(./assets/fonts/Mulish-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'TUV-Montserrat';
  font-style: normal;
  font-weight: 'bold';
  src: url(./assets/fonts/TUV-Montserrat-Bold.ttf) format('truetype');
}
html {
  scroll-behavior: smooth;
  // Custom scrollbar
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(255, 203, 154, 0.1);
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .ant-picker-dropdown ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--cl_gray400);
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul li {
  list-style-type: none;
}

body {
  font-family: 'Mulish', 'Roboto', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Custom menu
  .ant-switch-checked {
    background-color: var(--cl_primary);
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--cl_primary);
  }
  .itv-popover {
    .ant-popover-inner-content {
      padding: 0;
    }
    &.ant-popover {
      z-index: 1003;
    }
  }

  .itv-popuptimesheet {
    .ant-popover-inner-content {
      padding: 0;
      max-height: 400px;
      overflow: auto;
    }
  }

  .wrapperRequest {
    overflow-y: scroll;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;

    &.none {
      display: none;
    }
  }
  // Custom button
  .ant-btn-primary {
    background-color: var(--cl_primary500);
    color: var(--cl_white);
    border: 1px solid var(--cl_primary500);
    span {
      color: var(--cl_white);
    }
    &:hover {
      background-color: var(--cl_primary600);
      border: 1px solid var(--cl_primary600);
    }
    &:focus {
      background-color: var(--cl_primary600);
      border: 1px solid var(--cl_primary600);
    }
    &[disabled] {
      background-color: hsl(0, 0%, 95%);
      border-color: hsl(0, 0%, 90%);
      color: var(--cl_gray900) !important;
    }
    &[disabled] > span {
      color: var(--cl_gray900) !important;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
