.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  /* height: 100%; */
  width: 80%;
}

.dndflow aside {
  /* border-right: 1px solid #eee; */
  /* padding: 0px 10px; */
  font-size: 12px;
  /* background: #fcfcfc; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px;
  border-bottom: 5px solid rgb(225, 225, 225);
}

.dndflow .dndnode {
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2px 15px;
}
.dndflow .dndnode:hover {
  background: #d9d9d9 !important;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}
.dndflow .selectall {
  margin-top: 10px;
}

.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject body {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}
.dndflow .react-flow__node .react-flow__handle {
  background: none !important;
  border-radius: 0px;
  width: 0;
  height: 0;
}
.dndflow .react-flow__node:hover .react-flow__handle {
  width: 15px !important;
  height: 8px !important;
  border-radius: 3px !important;
  background-color: #fe753e !important;
}
/* .dndflow .react-flow__node .react-flow__handle {
  width: 15px;
  height: 8px;
  border-radius: 3px;
  background-color: #fe753e !important;
} */
/* floating edge */
.floatingedges {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.floatingedges .react-flow__handle {
  opacity: 0;
}

/* moi */
path.react-flow__edge-path {
  stroke-width: 3 !important;
  cursor: pointer;
  stroke: black;
  /* opacity: 0.5; */
}
path.react-flow__edge-path:hover {
  stroke-width: 5 !important;
  cursor: pointer;
  opacity: 1;
}

.react-flow__edge-textbg {
  cursor: pointer;
}
.react-flow__edge-text {
  font-weight: 700 !important;
  font-size: 12px !important;
}
.react-flow__edge:hover {
  z-index: 9999999;
}
.edgebutton-foreignobject body {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}
.react-flow__edge-path .labelAdd {
  font-size: '14px' !important;
  font-weight: 700;
  background: 'white' !important;
  border: '1px solid black' !important;
  border-radius: '10px';
  cursor: 'pointer';
  white-space: 'nowrap';
  display: 'inline-block';
  padding: '3px';
}
.react-flow__viewport:hover {
  background: 'red';
}
