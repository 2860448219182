.disable-color-message-text {
  .ant-notification-notice-message {
    color: unset;
    font-weight: 500;
    font-size: 18px;
  }
  .ant-notification-notice-close {
    top: 10px;
    right: 15px;
  }
  .ant-notification-notice-description {
    color: black;
  }
  .ant-notification-notice-icon {
    top: 50%;
    transform: TranslateY(-50%);
  }
}
