// customize react date picker library
.rdp {
  --rdp-cell-size: 36px;
  --rdp-accent-color: var(--cl_secondary500);

  font-family: 'Roboto';

  .rdp-head_cell {
    text-transform: capitalize;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--cl_neutral700);
  }

  .rdp-caption_label {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .rdp-nav_button {
    color: var(--cl_neutral500);
  }

  .rdp-day_range_middle {
    background-color: #e7edff;
    color: inherit;
  }
}

// customize overlay popover
.hrm-overlay-popover {
  .ant-popover-inner {
    border-radius: 8px;
  }
  .ant-popover-inner-content {
    padding: 1px;
  }
}
